import React, { Component } from 'react';
import Slider from 'react-slick';

export default class portfolioComponent extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div id="portfolio-carousel">
        <Slider {...settings}>
          <div className="portfolio-carousel__item">
            <a href="https://www.webmarketingdisuccesso.com/2019/02/shop-athena.html" target="_blank" rel="noopener noreferrer">
              <img alt="Enterprise Commerce grazie a Drupal e MongoDB"
                   src={require('../assets/images/athena_shop.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="https://www.webmarketingdisuccesso.com/2019/02/athena-corporate-website-2019.html" target="_blank" rel="noopener noreferrer">
              <img alt="Sito corporate con CMS Drupal"
                   src={require('../assets/images/athena_corporate.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="http://www.webmarketingdisuccesso.com/2014/04/johnn-barritt.html" target="_blank" rel="noopener noreferrer">
              <img alt="Installazione Drupal che prevede il commercio elettronico tramite il modulo Commerce.
                In collaborazione con i ragazzi di Wintrade che si occupano del SEO e del SEM, facciamo in modo di aumentare le vendite e la brand awarnes.
                Io mi occupo di tenere in ordine i moduli e apporto le modifiche che si rendono necessarie nel tempo."
                   src={require('../assets/images/johnbarritt.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="http://www.webmarketingdisuccesso.com/2014/07/agriturismo-corte-merlini.html" target="_blank" rel="noopener noreferrer">
            <img alt="Sotto il cofano ruggisce WordPress per questo agriturismo nelle terre dell'amarone.
              Il tema custom è stato sviluppato partendo dal framework Bootstrap che consente ti avere un valido punto di partenza per il responsive design."
              src={require('../assets/images/cortemerlini.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="http://www.webmarketingdisuccesso.com/2012/03/agv-helmets.html" target="_blank" rel="noopener noreferrer">
            <img alt="Sviluppo di CMS custom partendo dal framework CakePHP, per una delle aziende leader nella produzione di caschi da moto
              (è anche il casco di Valentino Rossi e del povero SIC). Il progetto e focalizzato sul catalogo prodotto, con una dettagliata descrizione/visualizzazione dei prodotti"
              src={require('../assets/images/AGV_helmets.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="http://www.webmarketingdisuccesso.com/2009/10/cielo-profondo.html" target="_blank" rel="noopener noreferrer">
              <img alt="Progettazione del foglio di stile CSS e personalizzazione degli scrip jQuery e del cms zenPHOTO"
              src={require('../assets/images/cieloprofondo.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="http://www.webmarketingdisuccesso.com/2009/03/arredare-con-stile.html" target="_blank" rel="noopener noreferrer">
              <img alt="Personalizzazione del foglio di stile CSS e del cms Wordpress in modo da ottenere un portfolio on line."
              src={require('../assets/images/arredareconstile.jpg')}/>
            </a>
          </div>
          <div className="portfolio-carousel__item">
            <a href="http://www.webmarketingdisuccesso.com/2010/12/negretto-camini.html" target="_blank"rel="noopener noreferrer">
              <img alt="Sviluppo del sito utilizzando la potenza di Drupal, creazione di un back-end personalizzato per facilitare l'inserimento dei contenuti."
            src={require('../assets/images/negrettocamini.jpg')}/>
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}