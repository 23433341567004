import React, { Component } from 'react';
import axios from 'axios';

export default class TagsCloud extends Component {

  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    const _this = this;
    this.serverRequest =
      axios
        .get('https://www.consulenzaweb.net/api/get_tag_index/')
        .then(function(result) {
          _this.setState({
            posts: result.data.tags,
          });
        });
  }

  componentWillUnmount() {
    this.serverRequest.abort();
  }

  render() {
    return (
      <div>
        {this.state.posts
          .filter(post => post.post_count > 2)
          .map(post => {
              if (post.post_count / 10 > 2) {
                return <span key={post.id}><a style={{ 'fontSize': '2em' }}
                                              href={'https://www.consulenzaweb.net/tag/' + post.slug + '/'}
                                              target="_blank" rel="noopener noreferrer"
                                              title={post.title}>{post.title}</a></span>;
              }
              return <span key={post.id}><a style={{ 'fontSize': post.post_count / 5 + 'em' }}
                                            href={'https://www.consulenzaweb.net/tag/' + post.slug + '/'}
                                            target="_blank" rel="noopener noreferrer"
                                            title={post.title}>{post.title}</a></span>;
            },
          )}
      </div>
    );
  }

}