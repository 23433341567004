import React, { Component } from 'react';
import axios from 'axios';

export default class Tumblr extends Component {

  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    const _this = this;
    this.serverRequest =
      axios
        .get('https://api.tumblr.com/v2/blog/webinteressante.tumblr.com/posts/?api_key=Q5xSAnYvJJNHE9WnqN8AxhRkW8Idd8sp2KIo7OC7FgUhGWiKpm')
        .then(function(result) {
          _this.setState({
            posts: result.data.response.posts,
          });
        });
  }

  componentWillUnmount() {
    this.serverRequest.abort();
  }

  render() {
    return (
      <ul>
        {this.state.posts
          .filter(function(item) {
            if (typeof item.link_url !== 'undefined') {
              return item.link_url.search('consulenzaweb.net') === -1;
            } else if (typeof item.body !== 'undefined') {
              return item.body.search('consulenzaweb.net') === -1;
            } else {
              return true;
            }
          })
          .map(post => <li key={post.id}><a
            href={post.post_url}
            target="_blank" rel="noopener noreferrer"
            title={post.title}>{post.title}</a></li>,
          )}
      </ul>
    );
  }

}