import React, { Component } from 'react';
import Slider from 'react-slick';

export default class testimonialComponent extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div id="testimonial">
        <Slider {...settings}>
          <div>
            <p>Diventiamo quello che vediamo. Noi diamo forma ai nostri strumenti e da lì in poi i nostri strumenti
              danno forma a noi. </p>
            <span className="testi_name">Marshall McLuhan [1911 - 1980, sociologo della comunicazione]</span></div>
          <div>
            <p>Le aziende vincenti trasformano in vincenti anche i proprio clienti. </p>
            <span className="testi_name"> Philip Kotler [economista]</span></div>
          <div>
            <p>Il nostro compito è quello di fare il cambiamento.
              Il nostro compito è quello di collegare le persone,
              di interagire con loro in un modo da renderli megliori di quando li abbiamo incontrati,
              in grado di arrivare dove vorrebbero andare. </p>
            <span className="testi_name"> Seth Godin [marketing guru]</span></div>
        </Slider>
      </div>
    );
  }
}