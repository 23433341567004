import React, { Component } from 'react';

let Link = require('react-router-dom').Link;

class NotFound extends Component {
  render() {
    return (
      <div id="service">
        <div className="container">
          <div className="dotted">
            <h3>Page Not Found</h3>
          </div>
          <div id="not-fount-error">
            <p>Sorry, there is nothing to see here.</p>
            <p><Link to="/" className="press press-terracotta press-round press-xl">Back to Home</Link></p>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;