import React, { Component } from 'react';
import logo from './../assets/images/logo.png';
import Portfolio from './slice_portfolioComponent';

class Header extends Component {
  render() {
    return (
      <div id="top">
        <header>
          <div className="container">
            <div className="row">
              <div className="one-third column">
                <div className="social_media">
                  <a href="https://www.facebook.com/emanuel.righetto" className="social facebook" target="_blank"
                     rel="noopener noreferrer">&nbsp;</a>
                  <a href="https://twitter.com/emanuelrighetto" className="social twitter" target="_blank"
                     rel="noopener noreferrer">&nbsp;</a>
                  <a href="https://www.linkedin.com/in/emanuelrighetto" className="social linkedin" target="_blank"
                     rel="noopener noreferrer">&nbsp;</a>
                  <a href="https://pinterest.com/emanuelrighetto/" className="social pinterest" target="_blank"
                     rel="noopener noreferrer">&nbsp;</a>
                  <a href="https://www.consulenzaweb.net/feed/" className="social feed" target="_blank"
                     rel="noopener noreferrer">&nbsp;</a>
                </div>
              </div>

              <div className="logo one-third column">
                <a href="https://www.emanuelrighetto.it" title="Web Consulting Services di Emanuel Righetto">
                  <img src={logo} alt="Web Consulting Services di Emanuel Righetto" width="99" height="61" title="Web Consulting Services"/>
                </a>
              </div>
              <div className="phone one-third column" itemScope itemType="http://schema.org/Person">
                <strong>Contattami:</strong> <span itemProp="telephone">340.5505033</span>
              </div>
              <br className="clear"/>
            </div>

            <div className="top_banner row">
              <div className="one-half column header_text">
                <h1>Emanuel Righetto</h1>
                <p>Mi piace definirmi artigiano del web.<br/>
                  Con il mondo della rete inizialmente mi sono approcciato come lo facevo da bambino con i miei
                  giocattoli: li smontavo per capire come funzionavano.<br/>
                  Questa curiosità predisposta al metodo empirico mi ha portato a scoprire e conoscere profondamente le
                  dinamiche dell'information and communication technology.
                  Dal 2002 metto a disposizione di privati, aziende ed enti pubblici il mio know how per lo sviluppo di progetti
                  web, occupandomi della creazione e promozione di siti internet.</p>
                <p>Apri <a href="https://it.linkedin.com/in/emanuelrighetto" target="_blank" rel="noopener noreferrer">
                  il mio profilo Linkedin</a> per consultare le mie competenze e le aziende per cui ho lavorato o con le
                  quali collaboro.</p>
                <p>Ho una particolare predilezione per il <a href="http://www.webmarketingdisuccesso.com/" target="_blank" rel="noopener noreferrer">
                  web marketing di successo</a>: dai un'occhiata al mio portfolio.</p>
              </div>

              <div className="one-half column banner">
                <Portfolio/>
              </div>
            </div>
          </div>

        </header>
      </div>
    );
  }
}

export default Header;