import React, { Component } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import oust from 'oust';
import Image from 'react-image-resizer';

export default class RecentPost extends Component {

  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    const _this = this;
    this.serverRequest =
      axios
        .get('https://www.consulenzaweb.net/wp-json/wp/v2/posts')
        .then(function(result) {
          _this.setState({
            posts: result.data,
          });
        });
  }

  componentWillUnmount() {
    this.serverRequest.abort();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div id="posts-carousel">
        <Slider {...settings}>
          {this.state.posts.map(post => {
              let srcs = oust(post.content.rendered, 'images');
              return <div key={post.id}>
                <a href={post.link} target="_blank" rel="noopener noreferrer">
                  <Image
                    src={srcs[0]}
                    alt={post.title.rendered}
                    width={220}
                    height={120}
                  /></a>
                <p className="imgtext">{post.title.rendered}</p>
              </div>;
            },
          )}
        </Slider>
      </div>
    );
  }

}