import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer>
        <div className="wrapper" itemScope itemType="http://schema.org/Person">
          <p>© {year} &nbsp;
            <a href="https://plus.google.com/116293302462831123981?rel=author" target="_blank" rel="noopener noreferrer">
              <span itemProp="name">Emanuel Righetto</span>
            </a> -
            P.IVA: 03971430230</p>
        </div>
      </footer>
    );
  }
}

export default Footer;