import React, { Component } from 'react';
import Testimonial from './slice_testimonialComponent';
import Tags from './slice_tagsComponents';
import RecentPost from './slice_postsComponents';
import Tumblr from './slice_tumblrComponent';

class Main extends Component {
  render() {
    return (
      <section id="main-content">
        <div id="service">
          <div className="container">
            <div className="dotted">
              <h3>Cosa faccio</h3>
            </div>
            <div className="service_block one-third column">
              <div className="block2">
                <a href="#webdevelopment" className="stacked"><span><strong>Sviluppo siti web</strong><br/>  Realizzazione e messa in produzione di siti web performanti</span></a>
              </div>
              <div className="block_text">
                <h4 id="webdevelopment">Sviluppo siti web</h4>
                <p>Solide codifiche secondo standard di sviluppo
                  condivisi: <b>css3</b>, <b>html5</b>, <b>php</b>, <b>Node.js</b>, <b>Git</b>, <b>Webpack</b>, <b>Gulp</b>.</p>
                <p>Ricerca e inventiva per ottenere lay out accattivanti che gratifichino la user experience del
                  visitatore.</p>
                <p><b>Realizzazione di siti web</b> che catturano l'attenzione di chi guarda, senza distrarlo dal
                  messaggio che si vuole veicolare.</p>
                <p>Soluzioni personalizzate tramite l'utilizzo dei più potenti software di gestione dei contenuti sia
                  come <b>WordPress</b> che <b>Drupal</b></p>
                <p>Soluzioni custom grazie ai performanti framework <b>Symfony</b> e <b>CakePHP</b>.</p>
                <p>Realizzazione <b>siti di e-commerce</b>: <b>Magento</b> o <b>PrestaShop</b>.</p>
              </div>
            </div>
            <div className="service_block one-third column">
              <div className="block1">
                <a href="#webmarketing" className="stacked"><span><strong>Web Marketing</strong><br/> SEO e SEM per ottimizzare il tuoi investimenti nell'online marketing</span></a>
              </div>
              <div className="block_text">
                <h4 id="webmarketing">Web Marketing</h4>
                <p>Il sito esteticamente bello che però non genera traffico e quindi non genera business è inutile.</p>
                <p>Farsi trovare su <b>Google</b>, <b>Yahoo</b> e <b>Bing</b> è di fondamentale importanza per la vita
                  di un sito. Il <b>posizionamento nei motori di ricerca</b> è un'esigenza imprescindibile.</p>
                <p>Oltre a questo prende sempre più piede la necessità di una corretta presenza nei social media, in
                  particolare <b>Facebook</b> e <b>Instagram</b>, per una corretta strategia SEO.</p>
                <p>Search Engine Marketing: campagne pay per click e contextual advertising per spingere il tuo business
                  sempre più in alto.</p>
              </div>
            </div>
            <div className="service_block one-third column">
              <div className="block3">
                <a href="#ecommerce" className="stacked"><span><strong>Strategie E-Commerce</strong><br/>Sviluppo della vision, degli obiettivi e la strategia precisa per un e-commerce di successo.</span></a>
              </div>
              <div className="block_text">
                <h4 id="ecommerce">Strategie E-Commerce</h4>
                <p>Un ecommerce di successo ha bisogno di strategie adeguate,
                  dalla scelta delle merci che i consumatori vogliono comprare,
                  alla presentazione dei prodotti,
                  dalla corretta gestione della logistica,
                  dalla giusta politica di prezzo alle garanzie e tutele per il consumatore seguito da tutto quello che
                  consente di realizzare un processo di acquisto indimenticabile.
                </p>
                <p>Il commercio elettronico offre la possibilità di agire in un sistema globale di compravendita per 24
                  ore al giorno e per 365 giorni all’anno,
                  pertanto un elevato fattore di guadagno consiste proprio nella nuova dimensione dello spazio/tempo di
                  riferimento, che possiede il mercato elettronico rispetto al mercato tradizionale.</p>
              </div>
            </div>
          </div>
        </div>

        <div id="featured">
          <div className="container">
            <div className="dotted">
              <h3>Expertise</h3>
            </div>
            <div className="featured_block row">
              <RecentPost/>
            </div>

            <div id="microblogging" className="row">
              <Tumblr/>
            </div>

            <div id="tag-cloud" className="row">
              <Tags/>
            </div>

            <div className="row">
              <div className="one-half column about">
                <h4>Soluzioni</h4>

                <div className="dotted2">&nbsp;</div>

                <p><img src={require('../assets/images/about-lrg.png')} alt="img" className="ialign" height="134" width="153"/>
                  Voi realizzare il tuo sito web? Vuoi farti trovare su internet? Vuoi estendere il tuo mercato nella
                  rete? Affidati alle mani di un esperto. </p>

                <ul>
                  <li className="dot">Realizzazione Siti Web</li>
                  <li className="dot">Restyling Grafico</li>
                  <li className="dot">Comunicazione aziendale online</li>
                  <li className="dot">Social Media Marketing</li>
                  <li className="dot">Search Engine Marketing</li>
                  <li className="dot">Search Engine Optimization</li>
                  <li className="dot">Direct Email Marketing</li>
                </ul>

              </div>

              <div className="one-half column">
                <div className="testimonial_block">
                  <h4>Ispirazioni</h4>
                  <Testimonial/>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div id="contact">
          <div className="container">
            <div className="dotted">
              <h3>Punti di contatto</h3>
            </div>
            <div className="row">
              <div className="one-half column">
                <h4>Riconoscimenti</h4>
                <ul id="badges">
                  <li>
                    <a id="iwa" href="https://www.iwa.it/soci/0310535/" target="_blank" rel="noopener noreferrer">
                      <img src="https://www.iwa.it/soci/0310535/logo.png" alt="International Webmasters Association" width="110" height="auto"/>
                    </a>
                  </li>
                  <li>
                    <a id="drupal" href="https://www.drupal.org/u/emanuelrighetto" target="_blank" rel="noopener noreferrer">
                      <img src={require('../assets/images/association_ind_member_badge.svg')} alt="Drupal Association Individual Member" width="150" height="auto"/>
                    </a>
                  </li>
                  <li>
                    <a id="aws" href="https://www.credly.com/badges/c12789a9-0ed6-4bc6-bfce-5d1e9529db31/public_url" target="_blank" rel="noopener noreferrer">
                      <img src={require('../assets/images/aws.badge.png')} alt="AWS Certified Solutions Architect - Associate" width="130" height="auto"/>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="one-half column" id="contact-details" itemScope itemType="http://schema.org/Person">
                <h4>Contattami</h4>
                <address>Web Consulting Services<br/>
                  Milano - Verona - Vicenza<br/>
                  Italy
                </address>
                <br/>
                <span className="tel-numbers" itemProp="telephone">+39.340.5505033</span>
                <br/>
                <br/>
                <span className="email" itemProp="email"><a href="mailto:posta@emanuelrighetto.it">posta@emanuelrighetto.it</a></span>
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default Main;