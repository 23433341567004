import React, { Component } from 'react';
import './assets/css/App.css';
import Header from './components/headerComponent';
import Main from './components/mainComponent';
import Footer from './components/footerComponent';
import NotFound from './components/notFoundComponent';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header/>
          <Switch>
            <Route exact path='/' component={Main}/>
            <Redirect from="/cookies.html" to="/"/>
            <Redirect from="/portfolio.html" to="/"/>
            <Redirect from="/contatti.html" to="/"/>
            <Redirect from="/press-center.html" to="/"/>
            <Redirect from="/consulenza-web.html" to="/"/>
            <Redirect from="/photos.html" to="/"/>
            <Route component={NotFound}/>
          </Switch>
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
